import { FC, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

interface IFadeToColor {
	className?: string;
}

const FadeToColor: FC<IFadeToColor> = ({ className, children }) => {
	const [viewPortEntered, setViewPortEntered] = useState(false);

	return (
		<p className={`${viewPortEntered ? className : ".fadeToBlack"}`}>
			<VisibilitySensor
				active={!viewPortEntered}
				onChange={(isVisible) => {
					if (isVisible) {
						setViewPortEntered(true);
					}
				}}
				delayedCall
			>
				{children}
			</VisibilitySensor>
		</p>
	);
};

export default FadeToColor;
